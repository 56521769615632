import React, { useState, useEffect} from 'react';
import { Modal, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
// import EditForm from './EditForm'



const Ambassador = ({user}) => {

    //const {deleteEmployee} = useContext(EmployeeContext)

    const [show, setShow] = useState(false);
    
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    useEffect(() => {
        handleClose()
    }, [user])

    return (
        <>
            <td>{user.message}</td>
            <td>{user.status}</td>
            <td>
                <OverlayTrigger
                    overlay={
                        <Tooltip id={`tooltip-top`}>
                            Details
                        </Tooltip>
                    }>
                    <button onClick={handleShow}  className="btn text-warning btn-act" data-toggle="modal"><i className="fa fa-edit"></i></button>
                </OverlayTrigger>
                <OverlayTrigger
                    overlay={
                        <Tooltip id={`tooltip-top`}>
                            Approve
                        </Tooltip>
                    }>
                    <button   className="btn text-success btn-act" data-toggle="modal"><i className="fa fa-check green-color"></i></button>
                </OverlayTrigger>
                
                
            </td>

        </>
    )
}

export default Ambassador;