import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import authSvg from '../../assests/update.svg';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { updateUser, isAuth, getCookie, signout } from '../../helpers/auth';
import {refferalRequest} from '../../redux/actions/usersAction'
import Footer from '../Footer.jsx';
import BreadCrumbs from '../BreadCrumbs';
import Sidebar from '../User/Sidebar.js';

const RefferalRequest = () => {
          const { auth,alert } = useSelector(state => state)
          const [formData, setFormData] = useState({
            email: '',
          });
        
          const {email } = formData;
          const handleChange = text => e => {
            setFormData({ ...formData, [text]: e.target.value });
          };
          const dispatch = useDispatch()
          const handleSubmit = e => {
            e.preventDefault();
            if (email) {
              
                  dispatch(refferalRequest(formData,auth.token,auth.user._id))
                  setFormData({
                     ...formData,
                     email: '',
                     
                   });
                
            } else {
              toast.error('Please fill all fields');
            }
          };
          useEffect(() => {
    
            if(alert.msg){
              toast.success(alert.msg);
              alert.msg = "";
            }
            if(alert.error){
              toast.error(alert.error);
              alert.msg = "";
            }
         }, [alert])
  return (
    <>
      <BreadCrumbs pageName="Profile"/>
      <section class="my-account-area">
      <ToastContainer />
      <div class="container pt--0 pb--0">
         <div class="row">
            <div class="col-lg-12">
               <div class="myaccount-page-wrapper">
                  <div class="row">
                     <div class="col-lg-3 col-md-4">
                      <Sidebar auth={auth}/>
                     </div>
                     <div class="col-lg-9 col-md-8">
                     <div class="tab-pane" aria-labelledby="dashboad-tab">
                              <div class="myaccount-content">
                                 <h3>Send Refferal Link</h3>
                                  
                              </div>
                              <div class="login_form_inner register_form_inner">
                 
                                    <form class="row login_form" action="" id="register_form"  onSubmit={handleSubmit}>
                                   
                                    <div class="col-md-12 form-group">
                                          <input type="text" class="form-control" id="email"  name={email} placeholder="Email Address" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Email Address'" onChange={handleChange('email')}/>
                                    </div>
                                   
                                    
                                    <div class="col-md-12 form-group">
                                          <button type="submit" value="submit" class="button button-register w-100">Submit</button>
                                    </div>
                                    </form>
                              </div>
                           </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
   <Footer/>
    </>
  );
};

export default RefferalRequest;
