import React, {useState, useEffect} from 'react'
import {useParams} from 'react-router-dom'
import axios from 'axios'
import { NavLink, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import BreadCrumbs from './BreadCrumbs';
import Footer from './Footer.jsx';
const baseUrl = process.env.REACT_APP_API_URL;
function ActivationEmail() {
    const {activation_token} = useParams()
    const [err, setErr] = useState('')
    const [success, setSuccess] = useState('')

    useEffect(() => {
        if(activation_token){
            const activationEmail = async () => {
                try {
                    const res = await axios.post(`${baseUrl}/api/activation`, {activation_token})
                    //const res = await axios.post('http://localhost:5000/api/activation', {activation_token})
                    setSuccess(res.data.msg)
                } catch (err) {
                    err.response.data.msg && setErr(err.response.data.msg)
                }
            }
            activationEmail()
        }
    },[activation_token])
    {err && toast.error(err)}
    {success && toast.success(success)}
    return (
    
            <>
            <BreadCrumbs pageName ="Activate"/>
             <section className="login_box_area section-margin">
             <ToastContainer />
             <div className="container">
                <div className="row">
                   <div className="col-lg-6">
                      <div className="login_box_img">
                         <div className="hover">
                            <h4>You are all ready member?</h4>
                            <p>There are advances being made in science and technology everyday, and a good example of this is the</p>
                            <NavLink className="button button-account" to="/login">Login</NavLink>
                         </div>
                      </div>
                   </div>
                   
                </div>
             </div>
          </section>
          <Footer/>
           </>
    )
}

export default ActivationEmail
