import React, { useEffect, useState } from 'react'

import Info from './Info'
import Posts from './Posts'
// import Saved from './Saved'

import { useSelector, useDispatch } from 'react-redux'
import LoadIcon from '../../../../assests/loading.gif'
import { getProfileUsers } from '../../../../redux/actions/profileAction'
import { useParams } from 'react-router-dom'


const Profile = () => {
    const { profile, auth } = useSelector(state => state)
    const dispatch = useDispatch()

    // const { id } = useParams()
    const [saveTab, setSaveTab] = useState(false)

    useEffect(() => {
        if(profile.ids.every(item => item !== auth.user._id)){
            dispatch(getProfileUsers({id: auth.user._id, auth}))
        }
    },[auth, dispatch, profile.ids])

    return (
        <div className="profile">
            
            <Info auth={auth} profile={profile} dispatch={dispatch} id={auth.user._id} />

            {
                auth.user._id &&
                <div className="profile_tab">
                    <button className={saveTab ? '' : 'active'} onClick={() => setSaveTab(false)}>Posts</button>
                    {/* <button className={saveTab ? 'active' : ''} onClick={() => setSaveTab(true)}>Saved</button> */}
                </div>
            }

            {
                profile.loading 
                ? <img className="d-block mx-auto" src={LoadIcon} alt="loading" />
                : <>
                    {
                       
                         <Posts auth={auth} profile={profile} dispatch={dispatch} id={auth.user._id} />
                    }
                </>
            }
            
        </div>
    )
}

export default Profile
