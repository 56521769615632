import React, { useState,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import {login} from '../redux/actions/authAction'
import { NavLink, useNavigate } from 'react-router-dom';
import GoogleLogin  from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import BreadCrumbs from './BreadCrumbs';
import Footer from './Footer.jsx';
import './common-css/social-login.css';
import { GLOBALTYPES } from '../redux/actions/globalTypes'
const baseUrl = process.env.REACT_APP_API_URL;
const initialState = {
  email: '',
  password: '',
}

function Login() {
    const {alert } = useSelector(state => state)
    let navigate = useNavigate();
    const initialState = { email: '', password: '' }
    const [userData, setUserData] = useState(initialState)
    const { email, password } = userData

    const dispatch = useDispatch()

    const handleChangeInput = e => {
        const { name, value } = e.target
        setUserData({...userData, [name]:value})
       
    }

    const handleSubmit = e => {
        e.preventDefault()
        if (email && password) {
           dispatch(login(userData,navigate))
           
        } else {
            toast.error('Please fill all fields');
       }
    }

   useEffect(() => {
      if(alert.error)
        toast.error(alert.error);
   }, [alert])

  const responseGoogle = async (response) => {
      try {
          const res = await axios.post(`${baseUrl}/api/google_login`, {tokenId: response.tokenId})
          //  setUserData({...userData, error:'', success: res.data.msg})
         //  localStorage.setItem('firstLogin', true)

         //  dispatch(login(userData))
         //  navigate("/dashboard")
         dispatch({ 
            type: GLOBALTYPES.AUTH, 
            payload: {
                token: res.data.access_token,
                user: res.data.user
            } 
        })

        localStorage.setItem("firstLogin", true)
        dispatch({ 
            type: GLOBALTYPES.ALERT, 
            payload: {
                success: res.data.msg
            } 
        })
        navigate('/dashboard')
      } catch (err) {
         dispatch({ 
            type: GLOBALTYPES.ALERT, 
            payload: {
                error: err.response.data.msg
            } 
        })
      }
  }

  const responseFacebook = async (response) => {
      try {
          const {accessToken, userID} = response
          const res = await axios.post(`${baseUrl}/api/facebook_login`, {accessToken, userID})
      
         //  setUserData({...userData, error:'', success: res.data.msg})
         //  localStorage.setItem('firstLogin', true)

         //  dispatch(login(userData))
         //  navigate("/dashboard")
         dispatch({ 
            type: GLOBALTYPES.AUTH, 
            payload: {
                token: res.data.access_token,
                user: res.data.user
            } 
        })

        localStorage.setItem("firstLogin", true)
        dispatch({ 
            type: GLOBALTYPES.ALERT, 
            payload: {
                success: res.data.msg
            } 
        })
        navigate('/dashboard')
      } catch (err) {
         //  err.response.data.msg && 
         //  setUserData({...userData, err: err.response.data.msg, success: ''})

         dispatch({ 
            type: GLOBALTYPES.ALERT, 
            payload: {
                error: err.response.data.msg
            } 
        })
      }
  }

  return (
    <>
     <BreadCrumbs pageName ="Login"/>
      <section className="login_box_area section-margin">
      <ToastContainer />
      <div className="container">
         <div className="row">
            <div className="col-lg-6">
               <div className="login_box_img">
                  <div className="hover">
                     <h4>New to our website?</h4>
                     <p>There are advances being made in science and technology everyday, and a good example of this is the</p>
                     <NavLink className="button button-account" to="/register">Create an Account</NavLink>
                  </div>
               </div>
            </div>
            <div className="col-lg-6">
               <div className="login_form_inner">
                  <h3>Log in to enter</h3>
                  <form className="row login_form" action="" id="contactForm" onSubmit={handleSubmit}>
                     <div className="col-md-12 form-group">
                        <input type="text" className="form-control" id="name" name="email" placeholder="Username" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Username'" onChange={handleChangeInput} value={email} />
                     </div>
                     <div className="col-md-12 form-group">
                        <input type="password" className="form-control" id="name" placeholder="Password" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Password'"  onChange={handleChangeInput} value={password} name="password" />
                     </div>
                     <div className="col-md-12 form-group">
                        <div className="creat_account">
                           <input type="checkbox" id="f-option2" name="selector"/>
                           <label for="f-option2">Keep me logged in</label>
                        </div>
                     </div>
                     <div className="col-md-12 form-group">
                        <button type="submit" value="submit" className="button button-login w-100">Log In</button>
                        <a href="#">Forgot Password?</a>
                     </div>
                     </form>
                     <div className="col-md-12 form-group">
                     
                     <div className="hr">Or Login With</div>

                     <GoogleLogin 
                      className="btn btn-primary text-uppercase mr-2 p-2"
                      clientId="650092217359-0oa4lv4ev8pm28ofbr2ru3q8tb046ni6.apps.googleusercontent.com"
                      onSuccess={responseGoogle}
                      cookiePolicy="single_host_origin"
                    />
                        
                    <FacebookLogin
                        className="btn-facebook ml-1 p-2"
                        appId="679509945443891"
                        autoLoad={false}
                        fields="name,email,picture"
                        callback={responseFacebook} 
                        icon="fa-facebook"
                    />
                    </div>
                    
                 
                  
               </div>
            </div>
         </div>
      </div>
   </section>
   <Footer/>
    </>
  );
};

export default Login;
