import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import authSvg from '../../assests/update.svg';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { updateUser, isAuth, getCookie, signout } from '../../helpers/auth';
import Footer from '../Footer.jsx';
import BreadCrumbs from '../BreadCrumbs';
import Sidebar from './Sidebar.js';

const BookingDetails = () => {
   const { auth,alert } = useSelector(state => state)
   if(alert.success)
          toast.success(alert.success);
  return (
    <>
      <BreadCrumbs pageName="Profile"/>
      <section class="my-account-area">
      <ToastContainer />
      <div class="container pt--0 pb--0">
         <div class="row">
            <div class="col-lg-12">
               <div class="myaccount-page-wrapper">
                  <div class="row">
                     <div class="col-lg-3 col-md-4">
                      <Sidebar auth={auth}/>
                     </div>
                     <div class="col-lg-9 col-md-8">
                           <div class="tab-pane" aria-labelledby="dashboad-tab">
                              <div class="myaccount-content">
                                 <h3>Booking Details</h3>
                                
                              </div>
                           </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
   <Footer/>
    </>
  );
};

export default BookingDetails;
