import React from 'react';
import {useHistory } from 'react-router-dom';
import BreadCrumbs from './BreadCrumbs';
import Footer from './Footer.jsx';
const AboutUs = () => {

  return (
    // 
    <>
     <BreadCrumbs pageName ="About Us"/>
     <section className="about-us section-padding">
        <div className="container">
            <div className="row">
                <div className="col-lg-6" data-aos="fade-right" data-aos-duration="2000">
                    <div className="about-text">
                        <h6 className="title-20 color-55 pb-10">About Us</h6>
                        <h3 className="playfair color-33 mb-40">We are providing best Accommodations services from 1999 for our Customers</h3>
                        <p className="mb-40">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer accumsan nulla ut tincidunt imperdiet. Nam orci orci</p>
                        <a href="#" className="btn-default solid-bg-btn pos-relative"><span>Get More Details <i className="ion-ios-arrow-thin-right"></i></span></a>
                    </div>
                </div>

                <div className="col-lg-6" data-aos="fade-left" data-aos-duration="2000">
                    <div className="about-img">
                        <img src="assets/images/about-img.jpg" alt="about img" className="img-fluid"/>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className="about-mission">
        <div className="container">
            <div className="row">
                <div className="col-md-4" data-aos="fade-up" data-aos-duration="2000">
                    <div className="single-mission text-center">
                        <i className="flaticon flaticon-rocket-1"></i>
                        <h6 className="title-20 color-33 mb-15 fw-600">Our mission</h6>
                        <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer accumsan nulla ut tincidunt imperdiet. Nam orci orci</p>
                    </div>
                </div>

                <div className="col-md-4" data-aos="fade-up" data-aos-duration="2500">
                    <div className="single-mission text-center">
                        <i className="flaticon flaticon-vision"></i>
                        <h6 className="title-20 color-33 mb-15 fw-600">Our mission</h6>
                        <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer accumsan nulla ut tincidunt imperdiet. Nam orci orci</p>
                    </div>
                </div>

                <div className="col-md-4" data-aos="fade-up" data-aos-duration="3000">
                    <div className="single-mission text-center">
                        <i className="flaticon flaticon-24-hours"></i>
                        <h6 className="title-20 color-33 mb-15 fw-600">Our mission</h6>
                        <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer accumsan nulla ut tincidunt imperdiet. Nam orci orci</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className="feature-section">
        <div className="img-area" data-aos="zoom-out" data-aos-duration="1500">
            <img src="assets/images/about-featured-img.jpg" alt="feature img" className="img-fluid"/>
        </div>
        <div className="features">
            <div className="single-feature" data-aos="fade-up" data-aos-duration="1500">
                <i className="ion-ios-loop-strong"></i>
                <h6 className="fw-700 color-ff mb-15">Quick and Affordable</h6>
                <p>Pellentesque vel lectus eget lorem blandit vulputate. Nullam dolor metus, </p>
            </div>

            <div className="single-feature" data-aos="fade-up" data-aos-duration="1800">
                <i className="ion-ios-medkit-outline"></i>
                <h6 className="fw-700 color-ff mb-15">Beautiful Interior Design</h6>
                <p>Pellentesque vel lectus eget lorem blandit vulputate. Nullam dolor metus, </p>
            </div>

            <div className="single-feature" data-aos="fade-up" data-aos-duration="2000">
                <i className="ion-android-favorite-outline"></i>
                <h6 className="fw-700 color-ff mb-15">Physical Health Benefits</h6>
                <p>Pellentesque vel lectus eget lorem blandit vulputate. Nullam dolor metus, </p>
            </div>

            <div className="single-feature" data-aos="fade-up" data-aos-duration="2500">
                <i className="ion-ios-reverse-camera-outline"></i>
                <h6 className="fw-700 color-ff mb-15">Full Air Condition Room</h6>
                <p>Pellentesque vel lectus eget lorem blandit vulputate. Nullam dolor metus, </p>
            </div>
        </div>
    </section>
    <section className="baeland-facts about-facts section-padding">
        <div className="container">
            <div className="row">
                <div className="col-lg-3 col-sm-6" data-aos="fade-up" data-aos-duration="1500">
                    <div className="facts-wrapper">
                        <div className="facts-common text-center pos-relative">
                            <i className="flaticon-user-4"></i>
                            <h4 className="title-35 fw-900 color-33 counter">1530</h4>
                            <span className="line d-block"></span>
                            <p className="facts-title color-55">Happy Clients</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-6" data-aos="fade-up" data-aos-duration="1800">
                    <div className="facts-wrapper">
                        <div className="facts-common text-center pos-relative">
                            <i className="flaticon-staff"></i>
                            <h4 className="title-35 fw-900 color-33 counter">60</h4>
                            <p className="facts-title color-55">Staffs</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-6" data-aos="fade-up" data-aos-duration="2000">
                    <div className="facts-wrapper">
                        <div className="facts-common text-center pos-relative">
                            <i className="flaticon-bed"></i>
                            <h4 className="title-35 fw-900 color-33 counter">100</h4>
                            <span className="line d-block"></span>
                            <p className="facts-title color-55">Rooms</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-6" data-aos="fade-up" data-aos-duration="2500">
                    <div className="facts-wrapper">
                        <div className="facts-common text-center pos-relative">
                            <i className="flaticon-like"></i>
                            <h4 className="title-35 fw-900 color-33 counter">632</h4>
                            <span className="line d-block"></span>
                            <p className="facts-title color-55">Love Us</p>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </section>
    <section className="about-cta section-padding">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="cta-content align-row-spacebetween">
                        <div className="content-txt">
                            <h3 className="color-00 pos-relative playfair" data-aos="fade-up" data-aos-duration="2000">Book your next vacation today</h3>
                            <p className="color-00 pt-20" data-aos="fade-up" data-aos-duration="2500">it’s simple just contact with us and one of our team member will assist you how to start.</p>
                        </div>
                        <a href="#" className="btn-default solid-bg-btn pos-relative radius-0" data-aos="fade-up" data-aos-duration="3000"><span>Book Now</span></a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Footer/>
    </>
  );
};

export default AboutUs;
