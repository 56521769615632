import React from 'react';
import { NavLink,Link,useNavigate} from 'react-router-dom'
import axios from 'axios';
import Suggessions from './components/Suggessions';
import {logout} from '../../redux/actions/authAction'
import { useDispatch, useSelector } from 'react-redux'

const Sidebar = ({auth}) => {
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const handleLogout = async () => {
        // try {
        //     await axios.get('/api/logout')
        //     localStorage.removeItem('firstLogin')
        //     window.location.href="/"
        // } catch (err) {
        //     navigate("/")
        // }
        dispatch(logout())
        localStorage.removeItem('firstLogin')
        window.location.href="/"
    }

    return (

        <nav>
            <div className="myaccount-tab-menu nav nav-tabs" id="nav-tab" role="tablist">
                <img src={auth.user?.avatar} />
                <NavLink to={`/dashboard`} activeClassName="active" className="nav-link">Dashboard</NavLink>
                <NavLink to={`/profile`} activeClassName="active" className="nav-link">Profile</NavLink>
                {
                 auth.user?.role === 0 &&
                <>
                <NavLink to={`/ambassador-request`} activeClassName="active"  className="nav-link">Ambassador</NavLink>
                <NavLink to={`/booking-details`} activeClassName="active"  className="nav-link" >Booking Details</NavLink>
               
                </>
                }
                {
                 auth.user?.role === 2 &&
                <>

                <NavLink to={`/booking-details`} activeClassName="active"  className="nav-link" >Booking Details</NavLink>
                <NavLink to={`/create-refferal-link`} activeClassName="active" className="nav-link">Create Refferal</NavLink>
                <NavLink to={`/refferal-list`} activeClassName="active"  className="nav-link">Referral List</NavLink>
                <NavLink to={`/refferal-payment-details`} activeClassName="active"  className="nav-link" >Payment Received</NavLink>
                </>
                }
                {
                 auth.user?.role === 1 &&
                <>
                <NavLink to={`/list-all-users`} activeClassName="active" className="nav-link">Users List</NavLink>
                <NavLink to={`/restaurant-list`} activeClassName="active" className="nav-link">Restaurant List</NavLink>
                <NavLink to={`/list-all-ambassador`} activeClassName="active" className="nav-link">Ambassadors List</NavLink>
                <NavLink to={`/approve-ambassador-request`} activeClassName="active" className="nav-link">Approve Ambassador</NavLink>
                </>
                }
                <Link to="" className="nav-link" type="Button" onClick={handleLogout}>Logout</Link>
                <Suggessions/>
            </div>
        </nav>

    );

}

export default Sidebar;