import React from 'react'
import { NavLink,Link,useNavigate} from 'react-router-dom';
import axios from 'axios';
import {logout} from '../redux/actions/authAction'
import { useDispatch, useSelector } from 'react-redux'

const ResponsiveNav = ()=>{
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const handleLogout = async () => {
        // try {
        //     await axios.get('/api/logout')
        //     localStorage.removeItem('firstLogin')
        //     window.location.href="/"
        // } catch (err) {
        //     navigate("/")
        // }
        dispatch(logout())
        localStorage.removeItem('firstLogin')
        window.location.href="/"
    }
    return(
    <>
    <div className="bodyOverlay"></div>
     <div className="responsive_nav">
        <div className="closemenu">
           <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/>
              <path fillRule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"/>
           </svg>
        </div>
         <nav className="nav_menu">
         <ul>
                                        <li>
                                            <NavLink to="/"> 
                                              Home
                                            </NavLink > 
                                        </li>
                                        <li>
                                            <NavLink   to="/shop">  
                                               Shop
                                            </NavLink > 
                                        </li>
                                        <li>
                                            <NavLink  to="/resturants"> 
                                               Resturants
                                            </NavLink > 
                                        </li>
                                        <li>
                                            <NavLink  to="/excursions"> 
                                               Excursions
                                            </NavLink >
                                        </li>
                                        <li>
                                            <NavLink  to="/aboutus"> 
                                               About us
                                            </NavLink >
                                        </li>
                                        <li>
                                            <NavLink  to="/contactus"> 
                                              Contact us
                                            </NavLink >
                                        </li>
                                        <li>
                                            <NavLink  to="/faq"> 
                                               FAQ
                                            </NavLink >
                                        </li>
                                        {
                                        !JSON.parse(localStorage.getItem('firstLogin')) ?
                                        (
                                        <>
                                        <li>
                                            <NavLink  to="/register"> 
                                               Register
                                            </NavLink >
                                        </li>
                                        <li>
                                            <NavLink  to="/login"> 
                                               Login
                                            </NavLink >
                                        </li>
                                        </>
                                        ):(
                                         <>
                                         <li>
                                         <NavLink to={`/dashboard`} activeClassName="active" className="nav-link">Dashboard</NavLink>
                                         </li>  
                                         <li>
                                         <NavLink to="" className="nav-link" type="Button" onClick={handleLogout}>Logout</NavLink>
                                         </li>
                                           
                                         </>   
                                        )}
                                    </ul>
                                    </nav>                        
     </div>
     </>
    );
}

export default ResponsiveNav;