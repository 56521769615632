import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import authSvg from "../../assests/update.svg";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { updateUser, isAuth, getCookie, signout } from "../../helpers/auth";
import Footer from "../Footer.jsx";
import BreadCrumbs from "../BreadCrumbs";
import Sidebar from "./Sidebar.js";
import OtherProfile from "./components/profile/OtherProfile";

const OtherUserProfile = () => {
  const { auth, alert } = useSelector((state) => state);
  if (alert.success) toast.success(alert.success);
  return (
    <>
      <BreadCrumbs pageName="Profile" />
      <section class="my-account-area">
        <ToastContainer />
        <div class="container pt--0 pb--0">
          <div class="row">
            <div class="col-lg-12">
              <div class="myaccount-page-wrapper">
                <div class="row">
                  <div class="col-lg-3 col-md-4">
                    <Sidebar auth={auth} />
                  </div>
                  <div class="col-lg-9 col-md-8">
                    <div class="tab-pane" aria-labelledby="address-edit-tab">
                      {/* <div class="myaccount-content">
                                 <h3>Profile</h3>
                                 <address>
                                    <p><strong>{auth.user?.fullname}</strong></p>
                                    <p>Address: {auth.user?.address}</p>
                                    <p>Mobile: {auth.user?.phone}</p>
                                 </address>
                                 <a href="#" class="check-btn sqr-btn"><i class="fa fa-edit"></i> Edit Profile</a>
                              </div> */}
                      <OtherProfile />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default OtherUserProfile;
