import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, Alert} from 'react-bootstrap';
import authSvg from '../../assests/update.svg';
import { ToastContainer, toast } from 'react-toastify';
import { updateUser, isAuth, getCookie, signout } from '../../helpers/auth';
import {fetchAllAmbassadorRequest} from '../../redux/actions/usersAction'
import Footer from '../Footer.jsx';
import BreadCrumbs from '../BreadCrumbs';
import Sidebar from '../User/Sidebar.js';
import Ambassador from './abmassador'
import Pagination from './Pagination';

const AmbassadorRequestList = () => {
  const { auth,alert,ambassador } = useSelector(state => state)
  console.log(ambassador)
  const ambassadorList = ambassador.filter(person => person.status == 0)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchAllAmbassadorRequest(auth.token))
    if(alert.error)
      toast.error(alert.error);
}, [])
    const [currentPage, setCurrentPage] = useState(1);
    const [userPerPage] = useState(2)

    const indexOfLastUser = currentPage * userPerPage;
    const indexOfFirstUser = indexOfLastUser - userPerPage;
    const currentUser = ambassadorList.slice(indexOfFirstUser, indexOfLastUser);
    const totalPagesNum = Math.ceil(ambassadorList.length / userPerPage);

  return (
    <>
      <BreadCrumbs pageName="Profile"/>
      <section class="my-account-area">
      <ToastContainer />
      <div class="container pt--0 pb--0">
         <div class="row">
            <div class="col-lg-12">
               <div class="myaccount-page-wrapper">
                  <div class="row">
                     <div class="col-lg-3 col-md-4">
                        <Sidebar auth={auth}/>
                     </div>
                     <div class="col-lg-9 col-md-8">
                     <div className="table-title">
                           <div className="row">
                                 <div className="col-sm-6">
                                    <h2>Manage <b>Ambassador</b></h2>
                                 </div>
                                 <div className="col-sm-6">

                                 </div>
                           </div>
                        </div>

                        <table className="table table-striped table-hover">
                           <thead>
                                 <tr>
                                    <th>Message</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                 </tr>
                           </thead>
                           <tbody>

                                    {
                                       currentUser.map(user => (
                                          
                                          <tr key={user.id}>
                                             <Ambassador user={user} />
                                          </tr>
                                       ))  
                                    }
                                    

                           </tbody>
                        </table>
                        <Pagination pages = {totalPagesNum}
                        setCurrentPage={setCurrentPage}
                        currentUser ={currentUser}
                        users = {ambassadorList} />
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
   <Footer/>
    </>
  );
};

export default AmbassadorRequestList;
