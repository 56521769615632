import React from 'react'

function BreadCrumbs(porps){
    return(
        <section className="baeland-breadcrumbs pos-relative">
        <div className="overlay"></div>
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="breadcrumbs-content align-row-spacebetween">
                        <ol className="breadcrumb" data-aos="fade-up" data-aos-duration="2000">
                            <li><a href="#">Home</a></li>
                            <li className="active">{porps.pageName}</li>
                        </ol>
                        <h2 className="color-ff" data-aos="fade-up" data-aos-duration="2000">{}</h2>
                    </div>
                </div>
            </div>
        </div>
    </section>
    )
}

export default BreadCrumbs;