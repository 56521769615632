import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, Alert } from "react-bootstrap";
import authSvg from "../../assests/update.svg";
import { ToastContainer, toast } from "react-toastify";
import { updateUser, isAuth, getCookie, signout } from "../../helpers/auth";
import { fetchAllRestaurant, deleteRestaurant } from "../../redux/actions/restaurantAction";
import Footer from "../Footer.jsx";
import BreadCrumbs from "../BreadCrumbs";
import Sidebar from "../User/Sidebar.js";
import User from "./user";
import Pagination from "./Pagination";

import AddRestaurant from "./AddRestaurant";
import EditRestaurant from "./EditRestaurant";

import DataTable, {
  FilterComponent,
} from "react-data-table-component-with-filter";



const RestaurantList = () => {
  const { auth, alert, restaurant } = useSelector((state) => state);
  const [onAdd, setOnAdd] = useState(false);
  const [onEdit, setOnEdit] = useState(false);
  const [restaurantData, setRestaurantData] = useState({});
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAllRestaurant(auth.token));
    if (alert.error) toast.error(alert.error);
    console.log(restaurant);
  }, []);

  const onAddSuccess = () => {
    dispatch(fetchAllRestaurant(auth.token));
    setOnAdd(false);
  }

  const onEditSuccess = () => {
    dispatch(fetchAllRestaurant(auth.token));
    setOnEdit(false);
  }

  const handleDelete = (id) => {
    if(window.confirm('Are you sure you want to delete ?')){
      dispatch(deleteRestaurant({id}, auth.token, () => {
        dispatch(fetchAllRestaurant(auth.token));
      }));
    }
  }
  
  const handleEdit = (data) => {
    setRestaurantData(data)
    setOnEdit(true);
  }

  const columns = [
    {
      name: "Category",
      selector: (row) => row.category,
      sortable: true,
      filterable: true,
    },
    {
      name: "Price",
      selector: (row) => row.price,
      sortable: true,
    },
    {
      name: "Discount",
      selector: (row) => row.discount,
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => <>
        <button onClick={() => handleDelete(row._id)} className="btn btn-outline-info">Delete</button>
        <button className="btn btn-outline-info" onClick={() => handleEdit(row)}>Edit</button>
      </>,
      sortable: true,
    },
  ];

  return (
    <>
      <BreadCrumbs pageName="Profile" />
      <section class="my-account-area">
        <ToastContainer />
        <div class="container pt--0 pb--0">
          <div class="row">
            <div class="col-lg-12">
              <div class="myaccount-page-wrapper">
                <div class="row">
                  <div class="col-lg-3 col-md-4">
                    <Sidebar auth={auth} />
                  </div>
                  <div class="col-lg-9 col-md-8">
                    <div className="table-title">
                      <div className="row">
                        <div className="col-sm-6">
                          <h2>
                            Manage <b>Restaurants</b>
                          </h2>
                        </div>
                        <div className="col-sm-6">
                          <button
                            className="btn btn-outline-info"
                            onClick={() => setOnAdd(true)}
                          >
                            Add Restaurant
                          </button>
                        </div>
                      </div>
                    </div>

                    <DataTable
                      columns={columns}
                      data={restaurant}
                      pagination
                      selectableRows
                      persistTableHead
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {onAdd && <AddRestaurant setOnAdd={setOnAdd} onAddSuccess={onAddSuccess} />}
      {onEdit && <EditRestaurant setOnEdit={setOnEdit} onEditSuccess={onEditSuccess} restaurantDataValue={restaurantData} />}
      <Footer />
    </>
  );
};

export default RestaurantList;
