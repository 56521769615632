import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import BreadCrumbs from './BreadCrumbs';
import Footer from './Footer.jsx';
const Shop = () => {

  return (
    // 
    <>
     <BreadCrumbs pageName ="Room"/>
     <section class="baeland-room list section-padding">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="section-title text-center">
                        <h2 class="color-1c pos-relative" data-aos="fade-up" data-aos-duration="2000">Our Rooms</h2>
                        <p class="color-1c text-uppercase" data-aos="fade-up" data-aos-duration="3000">Available Accommodations</p>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-4 col-sm-6" data-aos="fade-up" data-aos-duration="2000">
                    <div class="single-room">
                        <a href="#" class="room-img"><img src="assets/images/room-img1.jpg" alt="room img" class="img-fluid"/></a>
                        <h5 class="mb-15 playfair"><a href="#">Residential</a></h5>
                        <p>Pellentesque nulla magna, accumsan sed ante quis, gravida feugiat turpis. Vivamus et fringilla ligula. </p>
                        <span class="divider"></span>
                        <h4 class="price color-66 mb-15">$80.00 </h4>
                        <a href="#" class="btn-default read-more solid-bg-btn"><span>Book Now <i class="ion-android-arrow-dropright"></i></span></a>
                    </div>
                </div>

                
                <div class="col-md-4 col-sm-6" data-aos="fade-up" data-aos-duration="2500">
                    <div class="single-room">
                        <a href="#" class="room-img"><img src="assets/images/room-img2.jpg" alt="room img" class="img-fluid"/></a>
                        <h5 class="mb-15 playfair"><a href="#">Luxurious</a></h5>
                        <p>Pellentesque nulla magna, accumsan sed ante quis, gravida feugiat turpis. Vivamus et fringilla ligula. </p>
                        <span class="divider"></span>
                        <h4 class="price color-66 mb-15">$120.00 </h4>
                        <a href="#" class="btn-default read-more solid-bg-btn"><span>Book Now <i class="ion-android-arrow-dropright"></i></span></a>
                    </div>
                </div>

                
                <div class="col-md-4 col-sm-6" data-aos="fade-up" data-aos-duration="3000">
                    <div class="single-room">
                        <a href="#" class="room-img"><img src="assets/images/room-img3.jpg" alt="room img" class="img-fluid"/></a>

                        <h5 class="mb-15 playfair"><a href="#">Delux</a></h5>
                        <p>Pellentesque nulla magna, accumsan sed ante quis, gravida feugiat turpis. Vivamus et fringilla ligula. </p>
                        <span class="divider"></span>
                        <h4 class="price color-66 mb-15">$360.00 </h4>
                        <a href="#" class="btn-default read-more solid-bg-btn"><span>Book Now <i class="ion-android-arrow-dropright"></i></span></a>
                    </div>
                </div>

                
                <div class="col-md-4 col-sm-6" data-aos="fade-up" data-aos-duration="2000">
                    <div class="single-room">
                        <a href="#" class="room-img"><img src="assets/images/room-img4.jpg" alt="room img" class="img-fluid"/></a>

                        <h5 class="mb-15 playfair"><a href="#">Family Room</a></h5>
                        <p>Pellentesque nulla magna, accumsan sed ante quis, gravida feugiat turpis. Vivamus et fringilla ligula. </p>
                        <span class="divider"></span>
                        <h4 class="price color-66 mb-15">$160.00 </h4>
                        <a href="#" class="btn-default read-more solid-bg-btn"><span>Book Now <i class="ion-android-arrow-dropright"></i></span></a>
                    </div>
                </div>

              
                <div class="col-md-4 col-sm-6" data-aos="fade-up" data-aos-duration="2500">
                    <div class="single-room">
                        <a href="#" class="room-img"><img src="assets/images/room-img5.jpg" alt="room img" class="img-fluid"/></a>

                        <h5 class="mb-15 playfair"><a href="#">Double Bed Room</a></h5>
                        <p>Pellentesque nulla magna, accumsan sed ante quis, gravida feugiat turpis. Vivamus et fringilla ligula. </p>
                        <span class="divider"></span>
                        <h4 class="price color-66 mb-15">$250.00 </h4>
                        <a href="#" class="btn-default read-more solid-bg-btn"><span>Book Now <i class="ion-android-arrow-dropright"></i></span></a>
                    </div>
                </div>

                <div class="col-md-4 col-sm-6" data-aos="fade-up" data-aos-duration="3000">
                    <div class="single-room">
                        <a href="#" class="room-img"><img src="assets/images/room-img6.jpg" alt="room img" class="img-fluid"/></a>

                        <h5 class="mb-15 playfair"><a href="#">Single Bed Room</a></h5>
                        <p>Pellentesque nulla magna, accumsan sed ante quis, gravida feugiat turpis. Vivamus et fringilla ligula. </p>
                        <span class="divider"></span>
                        <h4 class="price color-66 mb-15">$145.00 </h4>
                        <a href="#" class="btn-default read-more solid-bg-btn"><span>Book Now <i class="ion-android-arrow-dropright"></i></span></a>
                    </div>
                </div>

               
                <div class="col-md-4 col-sm-6" data-aos="fade-up" data-aos-duration="2000">
                    <div class="single-room">
                        <a href="#" class="room-img"><img src="assets/images/room-img7.jpg" alt="room img" class="img-fluid"/></a>

                        <h5 class="mb-15 playfair"><a href="#">Twinn Bed Room</a></h5>
                        <p>Pellentesque nulla magna, accumsan sed ante quis, gravida feugiat turpis. Vivamus et fringilla ligula. </p>
                        <span class="divider"></span>
                        <h4 class="price color-66 mb-15">$130.00 </h4>
                        <a href="#" class="btn-default read-more solid-bg-btn"><span>Book Now <i class="ion-android-arrow-dropright"></i></span></a>
                    </div>
                </div>

              
                <div class="col-md-4 col-sm-6" data-aos="fade-up" data-aos-duration="2500">
                    <div class="single-room">
                        <a href="#" class="room-img"><img src="assets/images/room-img8.jpg" alt="room img" class="img-fluid"/></a>

                        <h5 class="mb-15 playfair"><a href="#">Double Delux</a></h5>
                        <p>Pellentesque nulla magna, accumsan sed ante quis, gravida feugiat turpis. Vivamus et fringilla ligula. </p>
                        <span class="divider"></span>
                        <h4 class="price color-66 mb-15">$190.00 </h4>
                        <a href="#" class="btn-default read-more solid-bg-btn"><span>Book Now <i class="ion-android-arrow-dropright"></i></span></a>
                    </div>
                </div>

             
                <div class="col-md-4 col-sm-6" data-aos="fade-up" data-aos-duration="3000">
                    <div class="single-room">
                        <a href="#" class="room-img"><img src="assets/images/room-img9.jpg" alt="room img" class="img-fluid"/></a>

                        <h5 class="mb-15 playfair"><a href="#">Single Delux</a></h5>
                        <p>Pellentesque nulla magna, accumsan sed ante quis, gravida feugiat turpis. Vivamus et fringilla ligula. </p>
                        <span class="divider"></span>
                        <h4 class="price color-66 mb-15">$125.00 </h4>
                        <a href="#" class="btn-default read-more solid-bg-btn"><span>Book Now <i class="ion-android-arrow-dropright"></i></span></a>
                    </div>
                </div>

            </div>
        </div>
    </section>  
    <Footer/>
    </>
  );
};

export default Shop;
