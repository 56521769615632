import React, { useState,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify';
import {NavLink} from 'react-router-dom';
import {register} from '../redux/actions/authAction'
import Footer from './Footer.jsx';
import BreadCrumbs from './BreadCrumbs.jsx';

const Register = () => {
  const { alert } = useSelector(state => state)
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password1: '',
    password2: '',
  });

  const { name, email, password1, password2} = formData;
  const handleChange = text => e => {
    setFormData({ ...formData, [text]: e.target.value });
  };
  const dispatch = useDispatch()
  const handleSubmit = e => {
    e.preventDefault();
    if (name && email && password1) {
      if (password1 === password2) {
        dispatch(register(formData))
              setFormData({
                ...formData,
                name: '',
                email: '',
                password1: '',
                password2: '',
              });
      } else {
        toast.error("Passwords don't matches");
      }
    } else {
      toast.error('Please fill all fields');
    }
  };

  useEffect(() => {
    
    if(alert.success){
      toast.success(alert.success);
      alert.success = '';
    }
    if(alert.email)
      toast.error(alert.email);
    if(alert.error){
      toast.error(alert.error);
      alert.error = '';
    }
 }, [alert])


  return (
      <>
      <BreadCrumbs pageName="Register"/>
      <section class="login_box_area section-margin">
      <ToastContainer />
      <div class="container">
        <div class="row">
            <div class="col-lg-6">
              <div class="login_box_img">
                  <div class="hover">
                    <h4>Already have an account?</h4>
                    <p>There are advances being made in science and technology everyday, and a good example of this is the</p>
                    <NavLink class="button button-account" to="/login">Login Now</NavLink>
                  </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="login_form_inner register_form_inner">
                  <h3>Create an account</h3>
                  <form class="row login_form" action="" id="register_form"  onSubmit={handleSubmit}>
                    <div class="col-md-12 form-group">
                        <input type="text" class="form-control" id="name" name={name} placeholder="Username" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Username'" onChange={handleChange('name')}/>
                    </div>
                    <div class="col-md-12 form-group">
                        <input type="text" class="form-control" id="email" name={email} placeholder="Email Address" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Email Address'" onChange={handleChange('email')}/>
                    </div>
                    <div class="col-md-12 form-group">
                        <input type="password" class="form-control" id="password" name={password1} placeholder="Password" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Password'" onChange={handleChange('password1')}/>
                    </div>
                    <div class="col-md-12 form-group">
                        <input type="password" class="form-control" id="confirmPassword" name={password2} placeholder="Confirm Password" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Confirm Password'" onChange={handleChange('password2')}/>
                    </div>
                    {/* <div class="col-md-12 form-group">
                        <div class="creat_account">
                          <input type="checkbox" id="f-option2" name="selector"/>
                          <label for="f-option2">Keep me logged in</label>
                        </div>
                    </div> */}
                    <div class="col-md-12 form-group">
                        <button type="submit" value="submit" class="button button-register w-100">Register</button>
                    </div>
                  </form>
              </div>
            </div>
        </div>
      </div>
    </section> 
    <Footer/>
    </>
  );
};

export default Register;
