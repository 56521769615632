import { GLOBALTYPES } from '../actions/globalTypes'

const ambassador =[]

const ambassadorReducer = (state = ambassador, action) => {
    switch(action.type){
        case GLOBALTYPES.GET_ALL_AMBASSADOR_REQUEST:
                return action.payload
        default:
            return state
    }
}

export default ambassadorReducer