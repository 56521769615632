import React from 'react';
import {useHistory } from 'react-router-dom';
import BreadCrumbs from './BreadCrumbs';
import Footer from './Footer.jsx';
const ContactUs = () => {

  return (
    // 
    <>
     <BreadCrumbs pageName ="Contact Us"/>
     <section className="google_map_section">
        <div id="map" className="mapHome1">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.4111318211726!2d-73.98303368522578!3d40.75298134304543!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c258f0710ab5eb%3A0x164a1e643b2fb871!2sGreen%20Tower%20Development!5e0!3m2!1sen!2sin!4v1636345069588!5m2!1sen!2sin" width="100%" height="450" styleName="border:0;" allowfullscreen="" loading="lazy"></iframe>
        </div>
    </section>
    <section className="contact-details clearfix">
        <div className="single-contact text-center align-center-center" data-aos="fade-up" data-aos-duration="2000">
            <i className="flaticon-phone-call"></i>
            <h5 className="title-20 ptb-20 fw-700 color-44 ">Phone No</h5>
            <p className="number"><a href="tel:+13 445 87 01 99">+13 445 87 01 99</a><br/><a href="tel:+13 445 87 01 99">+14 867 00 79 41</a></p>
        </div>

        <div className="single-contact text-center align-center-center" data-aos="fade-up" data-aos-duration="2500">
            <i className="flaticon-mail"></i>
            <h5 className="title-20 ptb-20 fw-700 color-44 ">Email Address</h5>
            <p className="number"><a href=""><span className="">Test@gmail.com</span></a><br/><a href=""><span className="">Test@gmail.com</span></a></p>
        </div>

        <div className="single-contact text-center align-center-center" data-aos="fade-up" data-aos-duration="3000">
            <i className="flaticon-placeholder"></i>
            <h5 className="title-20 ptb-20 fw-700 color-44 ">Address</h5>
            <p className="number fw-700 color-66">28 Green Tower, Street Name,<br/>New York City, USA</p>
        </div>
    </section>
    <section className="baeland-contact section-padding">
        <div className="container">
            <div className="row ">
                <div className="col-12">
                    <div className="wrapper">
                        <h3 className="contact-title  fw-600 color-22 pos-relative playfair d-inline" data-aos="fade-up" data-aos-duration="2000">Drop us a Line</h3>
                        <div className="abform-area clearfix pos-relative">
                            <form action="#" method="post" data-aos="fade-up" data-aos-duration="3000">
                                <div className="abinput-group name float-left">
                                    <label for="name3">Name : </label>
                                    <input type="text" name="name" id="name3"/>
                                </div>

                                <div className="abinput-group email float-left">
                                    <label for="email3">Email : </label>
                                    <input type="email" name="email" id="email3"/>
                                </div>

                                <div className="abinput-group subject float-left">
                                    <label for="sub3">Subject : </label>
                                    <input type="text" name="subject" id="sub3"/>
                                </div>

                                <div className="abinput-group phone float-left">
                                    <label for="phone3">Phone : </label>
                                    <input type="tel" name="phone" id="phone3"/>
                                </div>

                                <div className="abinput-group msg">
                                    <label for="msg3">Message : </label>
                                    <textarea name="msg" id="msg3"></textarea>
                                </div>
                                <button type="submit" className=" fw-700 color-ff">Send Message<i className="fa fa-long-arrow-right"></i></button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className="about-cta section-padding">
      <div className="container">
          <div className="row">
              <div className="col-12">
                  <div className="cta-content align-row-spacebetween">
                      <div className="content-txt">
                          <h3 className="color-00 pos-relative playfair" data-aos="fade-up" data-aos-duration="2000">Book your next vacation today</h3>
                          <p className="color-00 pt-20" data-aos="fade-up" data-aos-duration="2500">it’s simple just contact with us and one of our team member will assist you how to start.</p>
                      </div>
                      <a href="#" className="btn-default solid-bg-btn pos-relative radius-0" data-aos="fade-up" data-aos-duration="3000"><span>Book Now</span></a>
                  </div>
              </div>
          </div>
      </div>
  </section>
  <Footer/>
    </>
  );
};

export default ContactUs;
