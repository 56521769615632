import React from 'react'
const Footer = ()=>{
    return(

    <>
       <footer className="footer-sec">
        <div className="container">
           <div className="row">
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12"  data-aos="fade-up" data-aos-duration="2000">
                 <div className="ftr-box text-left">
                    <h3 className="footer-sub-head">
                       <a href="#" aria-label="">
                       <img src="./assets/images/logo.png" alt="" title=""/>
                       </a> 
                    </h3>
                    <div className="text-left">
                       <p className="text-white">
                          The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from “de Finibus Bonorum et Malorum”. [ <a href="#" target="_blank" className="text-white">Read More</a> ]
                       </p>
                    </div>
                 </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12"  data-aos="fade-up" data-aos-duration="2500">
                 <div className="ftr-link quick-link ">
                    <h3 className="footer-sub-head">Quick Links</h3>
                    <ul id="menu-footer-menu" className="menu">
                       <li><a href="#" aria-current="page">Home</a></li>
                       <li><a href="#" aria-current="page">About Us</a></li>
                       <li><a href="#" aria-current="page">Faq</a></li>
                       <li><a href="#" aria-current="page">Servies</a></li>
                       <li><a href="#" aria-current="page">Booing Room</a></li>
                       <li><a href="#" aria-current="page">Contact Us</a></li>                     
                    </ul>
                 </div>
              </div>
              <div className="col-xl-3 col-lg-5 col-md-6 col-sm-12 col-12"  data-aos="fade-up" data-aos-duration="3000">
                 <div className="ftr-link contactinfo">
                    <h3 className="footer-sub-head">Contact Information</h3>
                    <div className="ftr-fld ftr_icon">
                       <div className="fbc align-items-start">
                          <span>
                          <img src="./assets/images/flocation.png" className="img-fluid" alt=""/>	
                          </span> 
                          <em className="align-items-start">
                             Address: 
                             <div className="text-white">Bisharpur, West Bengal 721422</div>
                          </em>
                       </div>
                    </div>
                    <div className="ftr-fld ftr_icon">
                       <div className="fbc">
                          <span>
                          <img src="./assets/images/call.png" className="img-fluid" alt=""/>	
                          </span> 
                          <em>Phone: <a href="tel:+91 0000000000">+91 0000000000</a></em>
                       </div>
                    </div>
                    <div className="ftr-fld ftr_icon">
                       <div className="fbc">
                          <span>
                          <img src="assets/images/email.png" className="img-fluid" alt=""/>	
                          </span> 
                          <em>Email: <a href="mailto:test@gmail.com">test@gmail.com</a></em>
                       </div>
                    </div>
                 </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12"  data-aos="fade-up" data-aos-duration="3500">
                 <div className="ftr-box text-left">
                    <div className="ftr-link contactinfo">
                       <h3 className="footer-sub-head">Follow Us </h3>
                    </div>
                    <div className="subscribe_form">
                       <div className="emaillist" id="es_form_f1-n1">
                          <form>                              
                              
                                <input  type="email" name="esfpx_email" placeholder="Your Email Address" required="required"/>
                                <input type="submit" name="submit" className="es_subscription_form_submit" value="Send"/>
                          </form>
                       </div>
                    </div>
                    <div className="text-left">
                       <div className="social">
                          <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer nofollow" aria-label="Facebook" className="sk_facebook"><i className="fa fa-facebook"></i></a>
                          <a href="http://google.com/" target="_blank" rel="noopener noreferrer nofollow" aria-label="Google" className="sk_google"><i className="fa fa-google" aria-hidden="true"></i></a>
                          <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer nofollow" aria-label="instagram" className="sk_instagram"><i className="fa fa-instagram" aria-hidden="true"></i></a>
                          <a href="https://www.youtube.com/channel/UCpfODoZ-n6qxo_O1FwhLiiA" target="_blank" rel="noopener noreferrer nofollow" aria-label="instagram" className="sk_youtube"><i className="fa fa-youtube" aria-hidden="true"></i></a>
                       </div>
                    </div>
                 </div>
              </div>
           </div>
        </div>
     </footer>
     <div className="copyright-text text-center">
        <p>Copyright © 2014 - 2021 <a href="#">Bae Land</a> All Rights Reserved.</p>
     </div>
  
      <div className="scroll-top not-visible"><i className="fa fa-angle-up"></i></div>
        </>
    );
}

export default Footer;