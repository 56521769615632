import React from 'react'
import { NavLink } from 'react-router-dom';
const RightPopUp = ()=>{

    return(
        <>
        <div className="right_popup">
        <div className="popupHead">
            <div className="close">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/>
                <path fillRule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"/>
            </svg>
            </div>
        </div>
        <div className="popupwrap1">
            <h3 className="booking-subtitle">Welcome to Bae Land</h3>
            <h5 className="booking-menu__title">Book your villa at Bae Land</h5>
            <div className="sbe-booking-form">
            <form className="form">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="inputWrap">
                        <div className="form-group list_col check-in">
                            <input type="text" name="check-in" id="check-in" className="form-input" placeholder="07/11/2021"/>
                        </div>
                        <div className="form-group list_col check-out">
                            <input type="text" name="check-out" id="check-out" className="form-input" placeholder="08/11/2021"/>
                        </div>
                        <div className="form-group list_col guests">
                            <input type="number" name="guests" id="guests" max="2" name="" min="1" className="form-input" placeholder="1"/> 
                        </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="form-mention mb-3">All of our rooms accommodate a maximum of 2 people</div>
                    </div>
                    <div className="col-sm-12 Promocode_col">
                        <NavLink to="#" className="booking-subtitle" id="promocode-btn">Add Promocode</NavLink>
                        <div className="booking_promocode align-items-center mb-3">
                        <input type="text" name="" placeholder="Promocode"/>
                        <button className="PromoApply">Apply</button> <span className="close_promo">X</span>
                        </div>
                    </div>
                    <div className="col-sm-12 mb-3">
                        <div className="black_btn_group">
                        <NavLink className="btn" to="#">Book Now</NavLink>
                        <NavLink className="btn" to="#">Cancel</NavLink>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <NavLink to="#" className="booking-subtitle fineresvr">FIND A RESERVATION</NavLink>
                    </div>
                </div>
            </form>
            </div>
        </div>
        <div className="popupwrap2">
            <h3 className="booking-subtitle">Welcome to Bae Land</h3>
            <h3 className="booking-subtitle"><small>FIND A RESERVATION</small></h3>
            <form>
            <div className="form-group">
                <input type="text" name="" id="" className="form-input" placeholder="Email"/>
            </div>
            <div className="form-group">
                <input type="text" name="" id="" className="form-input" placeholder="CONFIRMATION NUMBER"/>
            </div>
            <div className="form-group">
                <div className="black_btn_group">
                    <NavLink className="btn" to="#">Find</NavLink>
                    <NavLink className="btn reservation_cancel" to="#">Cancel</NavLink>
                </div>
            </div>
            </form>
        </div>
    </div>
    </>
    );
}

export default RightPopUp;