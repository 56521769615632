import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { checkImage } from '../../utils/imageUpload'
import { GLOBALTYPES } from '../../redux/actions/globalTypes'
import { addRestaurant } from '../../redux/actions/restaurantAction'

const AddRestaurant = ({setOnAdd, onAddSuccess}) => {
    const initState = {
        category: '', price: 0.00, discount: 0.00, description: '', image: ''
    }
    const [restaurantData, setrestaurantData] = useState(initState)


    const { auth, theme, alert } = useSelector(state => state)
    const dispatch = useDispatch()

    useEffect(() => {
        //setrestaurantData(auth.user)
    }, [auth.user])


    
    const changeImage = (e) => {
        const file = e.target.files[0]

        const err = checkImage(file)
        if(err) return dispatch({
            type: GLOBALTYPES.ALERT, payload: {error: err}
        })

        setrestaurantData({ ...restaurantData, image:file })
    }


    const handleInput = e => {
        const { name, value } = e.target
        setrestaurantData({ ...restaurantData, [name]:value })
    }

    const handleSubmit = e => {
        e.preventDefault()
        console.log(restaurantData)
        dispatch(addRestaurant(restaurantData, auth.token, onAddSuccess))
        
    }

    return (
        <div className="edit_profile">
            <button className="btn btn-danger btn_close"
            onClick={() => setOnAdd(false)}>
                Close
            </button>

            <form onSubmit={handleSubmit}>
             <div className="info_avatar" style={{borderRadius: 'unset', width: '100%'}}>
                    <img src={restaurantData.image ? URL.createObjectURL(restaurantData.image) : 'assets/images/blog-img1.jpg'} 
                    alt="avatar" style={{filter: theme ? 'invert(1)' : 'invert(0)'}} />
                    <span>
                        <i className="fas fa-camera" />
                        <p>Change</p>
                        <input type="file" name="file" id="file_up"
                        accept="image/*" onChange={changeImage} />
                    </span>
                </div>
                <div className="form-group">
                    <label htmlFor="category">Category</label>
                    <div className="position-relative">
                        <input type="text" className="form-control" id="category"
                        name="category" value={restaurantData.category} onChange={handleInput} />
                        {/* <small className="text-danger position-absolute"
                        style={{top: '50%', right: '5px', transform: 'translateY(-50%)'}}>
                            {restaurantData.category.length}/25
                        </small> */}
                    </div>
                </div>

                <div className="form-group">
                    <label htmlFor="price">Price ($)</label>
                    <input type="text" name="price" value={restaurantData.price}
                    className="form-control" onChange={handleInput} />
                </div>

                <div className="form-group">
                    <label htmlFor="discount">Discount (%)</label>
                    <input type="text" name="discount" value={restaurantData.discount}
                    className="form-control" onChange={handleInput} />
                </div>

                <div className="form-group">
                    <label htmlFor="description">Description</label>
                    <input type="text" name="description" value={restaurantData.description}
                    className="form-control" onChange={handleInput} />
                </div>

               

                {alert.loading && <button className="btn btn-info w-100">Saving...</button>}
                {!alert.loading && <button className="btn btn-info w-100" type="submit">Save</button>}
            </form>
        </div>
    )
}

export default AddRestaurant
