import React from 'react';
import {useHistory } from 'react-router-dom';
import BreadCrumbs from './BreadCrumbs';
import Footer from './Footer.jsx';
const Excursions = () => {

  return (
    // 
    <>
     <BreadCrumbs pageName ="Excursions"/>
     <section className="about-us section-padding activities">
        <div className="container">
            <div className="row">
                <div className="col-lg-6" data-aos="fade-right" data-aos-duration="2000">
                    <div className="about-text">
                        <h6 className="title-20 color-55 pb-10">Activities</h6>
                        <h3 className="playfair color-33 mb-20">What is Lorem Ipsum?</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer accumsan nulla ut tincidunt imperdiet. Nam orci orci Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer accumsan nulla ut tincidunt imperdiet. Nam orci orci</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer accumsan nulla ut tincidunt imperdiet. Nam orci orci Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer accumsan nulla ut tincidunt imperdiet. Nam orci orci</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer accumsan nulla ut tincidunt imperdiet. Nam orci orci Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer accumsan nulla ut tincidunt imperdiet. Nam orci orci</p>
                        <a href="#" className="btn-default solid-bg-btn pos-relative mt-20"><span>Book Now <i className="ion-ios-arrow-thin-right"></i></span></a>
                    </div>
                </div>

                <div className="col-lg-6" data-aos="fade-left" data-aos-duration="2000">
                    <div className="about-img">
                        <img src="assets/images/about-img.jpg" alt="about img" className="img-fluid"/>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Footer/>
    </>
  );
};

export default Excursions;
