import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation} from 'react-router-dom';
import Home from './screens/Home';
import Login from './screens/Login';
import Register from './screens/Register';
import Admin from './screens/Admin';
import ForgetPassword from './screens/ForgetPassword';
import ResetPassword from './screens/ResetPassword';
import ActivationEmail from './screens/ActivationEmail';

import PrivateRoute from './Routes/PrivateRoute';
import AdminRoute from './Routes/AdminRoute';
import RightPopUp from './screens/RightPopUp';
import ResponsiveNav from './screens/ResponsiveNav';
import Footer from './screens/Footer';
import Shop from './screens/Shop';
import Resturants from './screens/Resturants';
import AboutUs from './screens/AboutUs';
import ContactUs from './screens/ContactUs';
import Faq from './screens/Faq';
import Excursions from './screens/Excursions';
import Navbar from './screens/NavBar';
import { BrowserRouter } from 'react-router-dom';
import Dashboard  from './screens/User/Dashboard.js';
import Profile  from './screens/User/Profile.js';
import OtherUserProfile  from './screens/User/OtherUserProfile.js';
import PostDetails  from './screens/User/PostDetails.js';
import Posts from './screens/User/Posts.js';
import AmbassadorRequest  from './screens/User/AmbassadorRequest.js';
import UserList from './screens/Admin/userList.js';
import AmbassadorList from './screens/Admin/ambassadorList.js';
import RestaurantList from './screens/Admin/restaurantList.js';
import BookingDetails from './screens/User/BookingDetails';
import AmbassadorRequestList from './screens/Admin/ambassadorRequestList';
import RefferalRequest from './screens/Ambassador/refferalRequest';
import RefferalPaymentDetails from './screens/Ambassador/refferalPaymentDetails';
import RefferalList from './screens/Ambassador/refferalList';

import io from 'socket.io-client'
import SocketClient from './SocketClient'
import Peer from 'peerjs'
import { GLOBALTYPES } from './redux/actions/globalTypes'
import { useState } from 'react';

import { SocketContext } from './redux/store'
const baseUrl = process.env.REACT_APP_API_URL;

const App= () =>{

  const { auth, status, modal, call } = useSelector(state => state)
  const [rsocket,setrSocket] = useState(null)
  const dispatch = useDispatch()

  useEffect(() => {
    const socket = io(`${baseUrl}`,{transports: ['websocket']})
    //dispatch({type: GLOBALTYPES.SOCKET, payload: socket})
    console.log('from App', socket)
    setrSocket(socket);
    return () => socket.close()
  },[dispatch])
 

  useEffect(() => {
    if (!("Notification" in window)) {
      alert("This browser does not support desktop notification");
    }
    else if (Notification.permission === "granted") {}
    else if (Notification.permission !== "denied") {
      Notification.requestPermission().then(function (permission) {
        if (permission === "granted") {}
      });
    }
  },[])
 
 
  useEffect(() => {
    const newPeer = new Peer(undefined, {
      path: '/', secure: true
    })
    
    dispatch({ type: GLOBALTYPES.PEER, payload: newPeer })
  },[dispatch])

 return (
  <SocketContext.Provider value={rsocket}>
    <BrowserRouter>
    <ResponsiveNav/>
    <RightPopUp/>
    <Navbar/>
    {auth.token && rsocket && <SocketClient />}
    <Routes>

            <Route path="/shop" element={<Shop/>}/>
            <Route path="/resturants" element={<Resturants/>}/>
            <Route path="/excursions" element={<Excursions/>}/>
            <Route path="/contactus" element={<ContactUs/>}/>
            <Route path="/aboutus" element={ <AboutUs/>}/>
            <Route path="/faq" element={<Faq/>}/>
            <Route path="/register" element={<Register/>}/>
            <Route path="/activate/:activation_token" element={<ActivationEmail/>}/>
            <Route path="/login" element={ <Login/>}/>
            <Route path="/dashboard" element={ <Dashboard/>}/>
            <Route path="/profile" element={ <Profile/>}/>
            <Route path="/profile/:id" element={ <OtherUserProfile/>}/>
            <Route path="/booking-details" element={ <BookingDetails/>}/>
            <Route path="/list-all-users" element={ <UserList/>}/>
            <Route path="/list-all-ambassador" element={ <AmbassadorList/>}/>

            <Route path="/restaurant-list" element={ <RestaurantList/>}/>

            <Route path="/ambassador-request" element={ <AmbassadorRequest/>}/>
            <Route path="/create-refferal-link" element={ <RefferalRequest/>}/>
            <Route path="/refferal-payment-details" element={ <RefferalPaymentDetails/>}/>
            <Route path="/refferal-list" element={ <RefferalList/>}/>
            <Route path="/approve-ambassador-request" element={ <AmbassadorRequestList/>}/>
            <Route path="/post" element={ <Posts/>}/>
            <Route path="/post/:id" element={ <PostDetails/>}/>
            <Route path="/" element={<Home/>}/>
              
       </Routes>
      
      </BrowserRouter>
      </SocketContext.Provider>
  );
}

export default App;
