import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import authSvg from "../../assests/update.svg";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { updateUser, isAuth, getCookie, signout } from "../../helpers/auth";
import Footer from "../Footer.jsx";
import BreadCrumbs from "../BreadCrumbs";
import Sidebar from "./Sidebar.js";
import PostCard from "./components/home/PostCard";
import LoadIcon from "../../assests/loading.gif";

const PostDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { homePosts, auth, theme } = useSelector((state) => state);
  const [post, setPost] = useState(null);
  if (alert.success) toast.success(alert.success);

  useEffect(() => {
    const spost = homePosts.posts.find((p) => p._id === id);
    setPost(spost);
  }, []);

  return (
    <>
      <BreadCrumbs pageName="Profile" />
      <section class="my-account-area">
        <ToastContainer />
        <div class="container pt--0 pb--0">
          <div class="row">
            <div class="col-lg-12">
              <div class="myaccount-page-wrapper">
                <div class="row">
                  <div class="col-lg-3 col-md-4">
                    <Sidebar auth={auth} />
                  </div>
                  <div class="col-lg-9 col-md-8">
                    <div class="tab-pane" aria-labelledby="address-edit-tab">
                      {/* <div class="myaccount-content">
                                 <h3>Profile</h3>
                                 <address>
                                    <p><strong>{auth.user?.fullname}</strong></p>
                                    <p>Address: {auth.user?.address}</p>
                                    <p>Mobile: {auth.user?.phone}</p>
                                 </address>
                                 <a href="#" class="check-btn sqr-btn"><i class="fa fa-edit"></i> Edit Profile</a>
                              </div> */}

                      <button onClick={() => navigate(-1)}>Back</button>
                      {post ? (
                        <PostCard key={post._id} post={post} theme={theme} />
                      ) : (
                        <img
                          src={LoadIcon}
                          alt="loading"
                          className="d-block mx-auto"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default PostDetails;
