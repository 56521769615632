import { GLOBALTYPES } from '../actions/globalTypes'

const restaurants =[]

const restaurantReducer = (state = restaurants, action) => {
    switch(action.type){
        case GLOBALTYPES.GET_ALL_RESTAURANT:
            return action.payload
        default:
            return state
    }
}

export default restaurantReducer