import { GLOBALTYPES } from './globalTypes'
import { getDataAPI,postDataAPI } from '../../utils/fetchData'

export const fetchAllUsers = (token)=>async (dispatch) => {
    try {
        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: true} })
        const res = await getDataAPI('all_infor', token)
        dispatch({ 
            type: GLOBALTYPES.GET_ALL_USERS, 
            payload: res.data
        })
    } catch (err) {
        dispatch({ 
            type: GLOBALTYPES.ALERT, 
            payload: {
                error: err.response.data.msg
            } 
        })
    }
}

export const ambassadorRequest = (data,token,id)=>async (dispatch) => {
    try {
        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: true} })
        const res = await postDataAPI(`ambassador_request/${id}`,data, token)
        dispatch({ 
            type: GLOBALTYPES.ALERT, 
            payload: res.data
        })
    } catch (err) {
        dispatch({ 
            type: GLOBALTYPES.ALERT, 
            payload: {
                error: err.response.data.msg
            } 
        })
    } finally {
        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: false} })
    }
}

export const refferalRequest = (data,token,id)=>async (dispatch) => {
    try {
        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: true} })
        const res = await postDataAPI(`send_refferal/${id}`,data, token)
        dispatch({ 
            type: GLOBALTYPES.ALERT, 
            payload: res.data
        })
    } catch (err) {
        dispatch({ 
            type: GLOBALTYPES.ALERT, 
            payload: {
                error: err.response.data.msg
            } 
        })
    } finally {
        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: false} })
    }
}

export const fetchAllAmbassadorRequest = (token)=>async (dispatch) => {
    try {
        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: true} })
        const res = await getDataAPI(`get_ambassador_request`,token)
        dispatch({ 
            type: GLOBALTYPES.GET_ALL_AMBASSADOR_REQUEST, 
            payload: res.data
        })
    } catch (err) {
        dispatch({ 
            type: GLOBALTYPES.ALERT, 
            payload: {
                error: err.response.data.msg
            } 
        })
    } finally {
        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: false} })
    }
}
