import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { follow, unfollow } from "../../../redux/actions/profileAction";
import { SocketContext } from "../../../redux/store";
const FollowBtn = ({ user }) => {
  const socket = React.useContext(SocketContext);
  const [followed, setFollowed] = useState(false);

  const { auth, profile, users } = useSelector((state) => state);
  const dispatch = useDispatch();

  const [load, setLoad] = useState(false);

  useEffect(() => {
    if (auth.user.following.find((item) => item._id === user._id)) {
      setFollowed(true);
    }
    return () => setFollowed(false);
  }, [auth.user.following, user._id]);

  const handleFollow = async () => {
    let selectedUser;
    if (typeof user === "string" || user instanceof String) {
      selectedUser = users.find((u) => u._id === user);
    } else {
      selectedUser = user;
    }
    // console.log(selectedUser, user);
    // return;
    if (load) return;

    setFollowed(true);
    setLoad(true);
    await dispatch(
      follow({ users: profile.users, user: selectedUser, auth, socket })
    );
    setLoad(false);
  };

  const handleUnFollow = async () => {
    let selectedUser;
    if (typeof user === "string" || user instanceof String) {
      selectedUser = users.find((u) => u._id === user);
    } else {
      selectedUser = user;
    }
    // console.log(selectedUser, user);
    // return;
    if (load) return;

    setFollowed(false);
    setLoad(true);
    await dispatch(
      unfollow({ users: profile.users, user: selectedUser, auth, socket })
    );
    setLoad(false);
  };

  return (
    <>
      {followed ? (
        <button className="btn btn-outline-danger" onClick={handleUnFollow}>
          UnFollow
        </button>
      ) : (
        <button className="btn btn-outline-info" onClick={handleFollow}>
          Follow
        </button>
      )}
    </>
  );
};

export default FollowBtn;
