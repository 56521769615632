import { GLOBALTYPES } from "./globalTypes";
import { getDataAPI, postDataAPI } from "../../utils/fetchData";
import { imageUpload } from "../../utils/imageUpload";

export const fetchAllRestaurant = (token) => async (dispatch) => {
  try {
    dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });
    const { data } = await getDataAPI("restaurant-list", token);
    console.log(data);
    dispatch({
      type: GLOBALTYPES.GET_ALL_RESTAURANT,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: GLOBALTYPES.ALERT,
      payload: {
        error: err.response.data.msg,
      },
    });
  } finally {
    dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
  }
};

export const addRestaurant = (data, token, cb) => async (dispatch) => {
  try {
    dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });
    try {
      if (data.image) {
        const imgs = await imageUpload([data.image]);
        if (imgs.length >= 1) {
          data.image = imgs[0].url;
        }
      }
    } catch {}
    const res = await postDataAPI(`add-restaurant`, data, token);
    dispatch({
      type: GLOBALTYPES.ALERT,
      payload: res.data,
    });
    cb();
  } catch (err) {
    dispatch({
      type: GLOBALTYPES.ALERT,
      payload: {
        error: err.response.data.msg,
      },
    });
  } finally {
    dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
  }
};

export const updateRestaurant = (data, token, cb) => async (dispatch) => {
  try {
    dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });
    try {
      if (data.image) {
        const imgs = await imageUpload([data.image]);
        if (imgs.length >= 1) {
          data.image = imgs[0].url;
        }
      }
    } catch {}
    const res = await postDataAPI(`update-restaurant`, data, token);
    dispatch({
      type: GLOBALTYPES.ALERT,
      payload: res.data,
    });
    cb();
  } catch (err) {
    dispatch({
      type: GLOBALTYPES.ALERT,
      payload: {
        error: err.response.data.msg,
      },
    });
  } finally {
    dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
  }
};

export const deleteRestaurant = (data, token, cb) => async (dispatch) => {
  try {
    dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });
    const res = await postDataAPI(`delete-restaurant`, data, token);
    dispatch({
      type: GLOBALTYPES.ALERT,
      payload: res.data,
    });
    cb();
  } catch (err) {
    dispatch({
      type: GLOBALTYPES.ALERT,
      payload: {
        error: err.response.data.msg,
      },
    });
  } finally {
    dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
  }
};
