import React, {useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import BreadCrumbs from './BreadCrumbs';
import { getDataAPI } from './../utils/fetchData'
import Footer from './Footer.jsx';


const Restaurant = ({category, price, discount, description, image}) => {

    const discountAmount = (price, discount) => parseFloat(price - parseFloat(discount) * parseFloat(price) * 0.01).toFixed(2)

    return (
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 blogbg" data-aos="fade-up" data-aos-duration="2000">
                    <div className="single-blog-post">
                        <div className="img-area pos-relative">
                            <a className="display-block" href="#"><img alt="blog post img" className="img-fluid" src={image ? image : 'assets/images/blog-img1.jpg'}/></a>
                        </div>
                        <div className="blog-content pt-20">
                            <h5><a href="#">{category} </a></h5>
                            <div className="featured-room">
                                <div className="room-info">
                                    {description}
                                </div>
                            </div>
                            <div>
                                <div style={{display: 'block'}}>Price: <span style={{textDecoration: 'line-through'}}>Rs.{price}</span> <span style={{fontWeight: 'bold'}}>Rs.{discountAmount(price, discount)}</span></div>
                                <span style={{display: 'block'}}>Discount: {discount}%</span>
                            </div>   
                            <div className="res-sec">
                                <a className="btn-default read-more solid-bg-btn" href="#">Book Now</a>
                            </div> 
                        </div>
                    </div>
        </div>
    );
}

const Resturants = () => {

    const [loading, setLoading] = useState(false)
    const [restaurants, setRestaurants] = useState([])

    useEffect(() => {

        (async () => {

           const {data} = await getDataAPI('restaurants');

           setRestaurants(data.data);

        })()


    },[]);


  return (
    // 
    <>
     <BreadCrumbs pageName ="Resturants"/>
     <section className="baeland-blog section-padding">
        <div className="container">
            <div className="row">
               {restaurants.map(row => <Restaurant {...row} />)}          
            </div>
        </div>
    </section>
    <Footer/>
    </>
  );
};

export default Resturants;
