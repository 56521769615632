import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { updateUser, isAuth, getCookie, signout } from '../../helpers/auth';
import { refreshToken, register } from '../../redux/actions/authAction'
import { getPosts } from '../../redux/actions/postAction'
import { getSuggestions } from '../../redux/actions/suggestionsAction'
import { getNotifies } from '../../redux/actions/notifyAction'
import '../../styles/global.css';
import Footer from '../Footer.jsx';
import BreadCrumbs from '../BreadCrumbs';
import Sidebar from './Sidebar.js';
import Posts from './components/home/Posts'
import Status from './components/home/Status'
import StatusModal from './components/home/StatusModal'
import LoadIcon from '../../assests/loading.gif'
import Header from './components/header/Header';

const Dashboard = () => {

   const dispatch = useDispatch()

  const { auth,alert } = useSelector(state => state)
  const { homePosts, status } = useSelector(state => state)

  useEffect(() => {
   dispatch(refreshToken())

   // const socket = io()
   // dispatch({type: GLOBALTYPES.SOCKET, payload: socket})
   // return () => socket.close()
 },[dispatch])

   useEffect(() => {
    if(alert.success){
      toast.success(alert.success);
      alert.success = "";
    }

   }, [alert])

   useEffect(() => {
      if(auth.token) {
        dispatch(getPosts(auth.token))
        dispatch(getSuggestions(auth.token))
        dispatch(getNotifies(auth.token))
      }
    }, [dispatch, auth.token])
 
  return (
    <>
      <BreadCrumbs pageName="Profile"/>
      <section class="my-account-area">
      <ToastContainer />
      <div class="container pt--0 pb--0">
         <div class="row">
            <div class="col-lg-12">
               <div class="myaccount-page-wrapper">
                  <div class="row">
                     <div class="col-lg-3 col-md-4">
                        <Sidebar auth={auth}/>
                     </div>
                     <div class="col-lg-9 col-md-8">
                        <div class="tab-content" id="nav-tabContent">
                           <div class="tab-pane fade active show" id="dashboad" role="tabpanel" aria-labelledby="dashboad-tab">
                              <div class="myaccount-content">
                                 <h3>Dashboard</h3>
                                
                              </div>
                              <Header/>
                           </div>
                           <Status />
                           {
                              homePosts.loading 
                              ? <img src={LoadIcon} alt="loading" className="d-block mx-auto" />
                              : (homePosts.result === 0 && homePosts.posts.length === 0)
                                    ? <h2 className="text-center">No Post</h2>
                                    : <Posts />
                           }
                          </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
   <Footer/>
   {status && <StatusModal />}
    </>
  );
};

export default Dashboard;
