import React, { useState, useEffect } from 'react';
import NavBar from './NavBar.jsx';
import RightPopUp from './RightPopUp.jsx';
import ResponsiveNav from './ResponsiveNav.jsx';


const Home = () => {

  return (
      <>
                {/* <div className="splash">
                    <div className="progressbar">
                        <div className="loading-lign"></div>
                    </div>
                </div> */}
                
                    <section className="videobanner homebanner">
                        <div className="video-item">
                        <div className="bannerbox">
                            <div className="videobox">
                                <div data-video-urls="https://res.cloudinary.com/dlfibdkmy/video/upload/v1708260832/banner-video_h62hsa.mp4" data-autoplay="true" data-loop="true" data-wf-ignore="true" className="bg-video fullscreen w-background-video w-background-video-atom">
                                    <video preload="metadata" autoPlay loop  muted="" playsInline="" data-wf-ignore="true">
                                        <source src="https://res.cloudinary.com/dlfibdkmy/video/upload/v1708260832/banner-video_h62hsa.mp4" data-wf-ignore="true"  type="video/mp4"/>
                                    </video>
                                </div>
                            </div>
                        </div>
                        </div>
                    </section>
      </>
  );
};

export default Home;
