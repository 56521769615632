import React from 'react'
import {NavLink } from 'react-router-dom'

const Navbar = ()=>{

    return(

        <>
            <header className="mainHeader">
               <section className="header_main innerpage">
                    <div className="htop">
                        <div className="container">
                            <div className="htop_row">                     
                                <div className="nav_wrapper">
                                   
                                    <span className="responsive_btn"><span></span></span>            
                                    <div className="clear"></div>
                                </div>
                                <div className="logo">
                                    <NavLink  to="/"><img src="../assets/images/logo.png" alt=""/></NavLink >
                                </div>
                                <div className="head_btn">
                                    <NavLink  to="#" className="btn booknow-btn"><span>Book now</span></NavLink >
                                </div>
                            </div>
                        </div>
                        </div>
                    </section>
                </header>
        </>
    );
}

export default Navbar;